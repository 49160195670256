/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

// import "../twix-css/twix-app.css"
// import "../twix-css/twix-responsive.css"

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="../Twix/twix-css/twix-app.css" />
        <link rel="stylesheet" href="../Twix/twix-css/twix-responsive.css" />
      </Helmet>
      <main className="overflow-hidden twix-cookie-dough">{children}</main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
