import React, { useState } from "react"
import ShowCounter from "./ShowCounter"
import ThankYouPage from "./ThankYouPage"

const IndexPage = ({ days, hours, minutes, seconds,fData }) => {
  
  const [showCounter, setShowCounter] = useState(true)
  const [thankYouPage, setThankYouPage] = useState(false)
  return (
    <>
      {showCounter && (
        <ShowCounter
          days={days}
          hours={hours}
          minutes={minutes}
          seconds={seconds}
          fData={fData}
          setShowCounter={setShowCounter}
          setThankYouPage={setThankYouPage}
        />
      )}
      {thankYouPage && (
        <ThankYouPage
          days={days}
          hours={hours}
          minutes={minutes}
          seconds={seconds}
        />
      )}
    </>
  )
}
export default IndexPage
