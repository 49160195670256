import React, { useEffect } from "react"
import { Container, Row, Figure } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"

const SoldOutPage = props => {
  useEffect(() => {
    document.body.style.backgroundColor = "#fce9d1"
  }, [])

  return (
    <section className="sold-out-page py-5 text-center position-relative">
      <Figure>
        <StaticImage
          className="position-absolute twix-bar-left d-lg-none d-block"
          src="../../images/twix-bar.png"
          alt="Twix Choclate Bar"
          quality={100}
          placeholder="none"
        />
      </Figure>
      <div className="mt-3 position-relative">
        <Row>
          <div className="col-1">
            <Figure>
              <StaticImage
                className="position-absolute twix-bar-left d-lg-block d-none"
                src="../../images/twix-bar.png"
                alt="Twix Choclate Bar"
                quality={100}
                placeholder="none"
              />
            </Figure>
          </div>
          <div className="col-10">
            <h4 className="mx-lg-5 px-lg-5 mt-lg-0 mt-5 pt-md-5">
              We’re fresh out of TWIX<sup>&reg;</sup> Cookie Dough
            </h4>
            <h1 className="py-2 pb-lg-5 mb-lg-5 mx-3">- SORRY!</h1>
          </div>
          <div className="col-1">
            <Figure>
              <StaticImage
                className="position-absolute twix-bar-right d-lg-block d-none"
                src="../../images/twix-bar.png"
                alt="Twix Choclate Bar"
                quality={100}
                placeholder="none"
              />
            </Figure>
          </div>
        </Row>
      </div>
      <h5 className="mx-3">
        All 1,000 TWIX<sup>&reg;</sup> Cookie Dough boxes have been claimed.
        <span className="d-md-block">
          Missed out? You still might have a chance to score a box via social.
        </span>
      </h5>
      <p>
        Plus, check shelves in-store once they’re available nationwide starting
        this December.
      </p>
      <h6 className="text-uppercase">
        Follow @TWIX<sup>&reg;</sup>
      </h6>
      <div className="pb-5 pb-lg-0">
        <a
          className="text-decoration-none"
          href="https://www.instagram.com/twix"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Figure>
            <StaticImage
              className="social-icons me-3"
              src="../../images/instagram-icon.png"
              alt="Instagram Icon"
              quality={100}
              placeholder="none"
            />
          </Figure>
          <span className="visually-hidden">(opens in new window)</span>
        </a>
        <a
          className="text-decoration-none"
          href="https://twitter.com/twix"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Figure>
            <StaticImage
              className="social-icons ms-3"
              src="../../images/twitter-icon.png"
              alt="Twitter Icon"
              quality={100}
              placeholder="none"
            />
          </Figure>
          <span className="visually-hidden">(opens in new window)</span>
        </a>
      </div>
      <Figure>
        <StaticImage
          className="position-absolute twix-bar-right d-lg-none d-block"
          src="../../images/twix-bar.png"
          alt="Twix Choclate Bar"
          quality={100}
          placeholder="none"
        />
      </Figure>
    </section>
  )
}
export default SoldOutPage
