import React, { useEffect } from "react"

import { Container, Row, Figure } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import DateTimeDisplay from "./DateTimeDisplay"

const ThankYouPage = props => {
  useEffect(() => {
    document.body.style.backgroundColor = "#fce9d1"
  }, [])

  return (
    <section className="thank-you-page py-5 text-center position-relative">
      <Figure>
        <StaticImage
          className="position-absolute twix-bar-left d-lg-none d-block"
          src="../../images/twix-bar.png"
          alt="Twix Choclate Bar"
          quality={100}
          placeholder="none"
        />
      </Figure>
      <h1 className="heading px-2 mt-5 mb-0 pt-5 mt-lg-0 pt-lg-0">
        The Cookie Dough countdown is on.
      </h1>
      <div className="mt-lg-3 position-relative">
        <Row>
          <div className="col-1">
            <Figure>
              <StaticImage
                className="position-absolute twix-bar-left d-lg-block d-none"
                src="../../images/twix-bar.png"
                alt="Twix Choclate Bar"
                quality={100}
                placeholder="none"
              />
            </Figure>
          </div>
          <div className="col-10">
            <Container className="show-counter">
              <div className="countdown-block">
                {/* <DateTimeDisplay value={props.days} type={"days"} /> */}
                <DateTimeDisplay value={props.hours} type={"hours"} />
                <DateTimeDisplay value={props.minutes} type={"minutes"} />
                <DateTimeDisplay value={props.seconds} type={"seconds"} />
              </div>
            </Container>
          </div>
          <div className="col-1">
            <Figure>
              <StaticImage
                className="position-absolute twix-bar-right d-lg-block d-none"
                src="../../images/twix-bar.png"
                alt="Twix Choclate Bar"
                quality={100}
                placeholder="none"
              />
            </Figure>
          </div>
        </Row>
      </div>
      <h5 className="content pt-lg-5 pt-3 mt-lg-4 mx-5 mx-lg-3">
        We heard you love Cookie Dough just as much as us!
      </h5>
      <p className="content px-2 pb-5 mb-5 pb-lg-0 mb-lg-0">
        Check back on June 21st for a chance to claim a free box of TWIX
        <sup>&reg;</sup> Cookie Dough.
      </p>

      <Figure>
        <StaticImage
          className="position-absolute twix-bar-right d-lg-none d-block"
          src="../../images/twix-bar.png"
          alt="Twix Choclate Bar"
          quality={100}
          placeholder="none"
        />
      </Figure>
    </section>
  )
}
export default ThankYouPage
