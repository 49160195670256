import React from "react"

import Layout from "../../components/cookie dough components/components/layout"
import HomePage from "../../components/cookie dough components/components/HomePage"

const IndexPage = () => {
  let currentDate = new Date().getTime()
  let countDownDate = new Date(currentDate + 0.5 * 60000)
  console.log(countDownDate)

  let formDate = new Date(currentDate + 0.25 * 60000)
  console.log(formDate)

  // let countDownDate = new Date("June 21, 2022 09:00:00 EDT")

  // let formDate = new Date("June 20, 2022 23:59:59 EDT")
  return (
    <Layout>
      <HomePage countDownDate={countDownDate} formDate={formDate} />
    </Layout>
  )
}

export default IndexPage
