import React, { useRef } from "react"
import DateTimeDisplay from "./DateTimeDisplay"
import Form from "./Form"
import { Container, Figure, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"

const ShowCounter = ({
  days,
  hours,
  minutes,
  seconds,
  fData,
  setShowCounter,
  setThankYouPage,
}) => {
  return (
    // <Layout>
    //   <Seo title="Home" />
    <section className="countdown-page site-bg py-4 py-md-5">
      <Container className="mt-3">
        <Row className="align-items-center">
          <Col lg={5} className="text-center">
            <Figure>
              <StaticImage
                src="../../images/twix-logo.png"
                alt="Twix Logo"
                quality={100}
                placeholder="none"
              />
            </Figure>
          </Col>
          <Col lg={7} className="content px-3">
            <h1 className="mb-2">
              TWIX<sup>&reg;</sup> Cookie Dough
            </h1>
            {/* <h5>-ready to bowl you over</h5> */}
            <p>
              To celebrate National Cookie Dough Day on June 21st, TWIX
              <sup>&reg;</sup> will reward the first 1,000 fans with an
              exclusive first taste of TWIX<sup>&reg;</sup> Cookie Dough bars.
            </p>
            <p>
              This all-new flavor combines classic TWIX<sup>&reg;</sup> cookie
              bars with a creamy cookie dough-flavored layer, sprinkled with
              chocolate cookie bits – all coated with rich milk chocolate. The
              best part? You don’t need a spoon to enjoy it!
            </p>
          </Col>
        </Row>
      </Container>
      <div className="position-relative">
        <Container className="show-counter">
          <div className="countdown-block">
            {/* <DateTimeDisplay value={days} type={"days"} /> */}
            <DateTimeDisplay value={hours} type={"hours"} />
            <DateTimeDisplay value={minutes} type={"minutes"} />
            <DateTimeDisplay value={seconds} type={"seconds"} />
          </div>
        </Container>
        {fData.days + fData.hours + fData.minutes + fData.seconds > 0 && (
          <Container>
            <Row>
              <Col md={2}>
                <Figure>
                  <StaticImage
                    className="position-absolute twix-left"
                    src="../../images/twix-left.png"
                    alt="Twix Choclate Left"
                    quality={100}
                    placeholder="none"
                  />
                </Figure>
              </Col>
              <Col md={8}>
                <Form
                  setShowCounter={setShowCounter}
                  setThankYouPage={setThankYouPage}
                />
              </Col>
              <Col md={2}>
                <Figure>
                  <StaticImage
                    className="position-absolute twix-right"
                    src="../../images/twix-right.png"
                    alt="Twix Choclate Right"
                    quality={100}
                    placeholder="none"
                  />
                </Figure>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </section>
    // </Layout>
  )
}
export default ShowCounter
